import trimStart from "lodash-es/trimStart";

import { HUB_SERVER } from "constants/hub";

export class DomainService {
  private HUB_API_ROOT = `${HUB_SERVER}/api/v1/hub`;
  private API_ROOT = "/api/v1/app";
  private _domain = "";
  private _hasSetDomain = false;

  constructor(config: { domain?: string }) {
    if (config.domain) {
      this._domain = config.domain;
      this._hasSetDomain = true;
    }
  }

  get domainWithProtocol(): string {
    if (this._domain) {
      const withTrimmedProtocol = trimStart(this._domain, "https://");
      return `https://${withTrimmedProtocol}`;
    }
    return "";
  }

  get hasSetDomain(): boolean {
    return this._hasSetDomain;
  }

  setDomain(newDomain: string) {
    this._domain = newDomain;
    this._hasSetDomain = true;
  }

  get apiRoot(): string {
    if (this._domain) {
      const withTrimmedProtocol = trimStart(
        `${this._domain}${this.API_ROOT}`,
        "https://",
      );
      return `https://${withTrimmedProtocol}`;
    }
    return this.API_ROOT;
  }

  get hubApiRoot(): string {
    return this.HUB_API_ROOT;
  }
}

export const domainService = new DomainService({});
