export const HUB_PLUGINS = "hub-plugins";
export const HUB_PLUGINS_ZIP = "hub-plugins-zip";
export const HUB_DOMAIN = "hub.wordparrot.com";
export const HUB_SERVER =
  process.env.HUB_SERVER_HOST || `https://${HUB_DOMAIN}`;

export const HUB_SITES = "hub-sites";

export const HUB_USERS = "hub-users";

export const CONTENT = "content";

export const FORM_JSON = "form.json";
export const PLUGIN_JSON = "plugin.json";
export const INDEX_JS = "index.js";

export const HUB_CATEGORIES = "hub-categories";

export const HUB_STATUS_DRAFT = "draft";
export const HUB_STATUS_PUBLISH = "publish";
export const HUB_STATUS_PRIVATE = "private";

export const HUB_PLUGIN_VERSIONS = "versions";

// Operations
export const PUSH = "push";
export const FETCH = "fetch";
export const DOWNLOAD = "download";
export const INSTALL = "install";
export const REGISTER_SITE = "register-site";
