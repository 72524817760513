import { Observable } from "rxjs";
import { createEntityQuery } from "@datorama/akita";
import { filter, map, tap } from "rxjs/operators";
import { isUndefined } from "lodash-es";
import orderBy from "lodash-es/orderBy";

import { Banner } from "state/banner/interface";
import { Dns, Seo, Site, Social, Theme } from "./interface";
import { Image } from "state/image/interface";
import { SiteState, siteStore } from "./store";

export const siteQuery = createEntityQuery<SiteState>(siteStore);

export const sites$: Observable<Site[]> = siteQuery.selectAll();
export const site$: Observable<Site> = siteQuery.selectActive().pipe(
  map((site) => {
    return site as Site;
  }),
  filter((site) => !isUndefined(site)),
);
export const dns$: Observable<Dns> = site$.pipe(map((site: Site) => site.dns));
export const seo$: Observable<Seo> = site$.pipe(map((site: Site) => site.seo));
export const social$: Observable<Social> = site$.pipe(
  map((site: Site) => site.social),
);
export const theme$: Observable<Theme> = site$.pipe(
  map((site: Site) => site.theme),
);
export const banners$: Observable<Banner[]> = site$.pipe(
  map((site) => site.banners || []),
  map((banners) => orderBy(banners, ["order"], ["asc"])),
);
export const bannerImages$: Observable<Image[]> = banners$.pipe(
  map((banners) => banners.map((banner) => banner.image)),
);

// export const bannerRGB$: Observable<{
//     r: number;
//     g: number;
//     b: number;
// }> = theme$.pipe(
//     filter(theme => !isUndefined(theme?.banner)),
//     map((theme: Theme) => {
//         var r = parseInt(theme.banner.slice(1, 3), 16),
//             g = parseInt(theme.banner.slice(3, 5), 16),
//             b = parseInt(theme.banner.slice(5, 7), 16);
//         return {
//             r,
//             g,
//             b,
//         };
//     })
// );

export const enableCommunication$: Observable<boolean> = site$.pipe(
  map((site) => {
    return site.enableCommunication as boolean;
  }),
  filter((enableCommunication) => enableCommunication),
);
