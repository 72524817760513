// You cannot use TS special path imports here!
import { Dns, Seo, Site, Social, Theme } from "wordparrot-types";

export const hubHost = "hub.wordparrot.com";

export const primaryMain = "#35679b";
export const primaryDark = "#1a334d";
export const primaryLight = "#85a3c3";
export const primaryContrastText = "#ffffff";

export const bgBar = "#0e2d69";
export const contrastText = "#bbbbbb";

export const secondaryMain = "#679b35";
export const secondaryDark = "#334d1a";
export const secondaryLight = "#a3c385";
export const secondaryContrastText = "#ffffff";

export const info = "#0080FF";
export const success = "#08a508";
export const error = "#c3000f";
export const warning = "#dbbd0b";

export const textPrimary = "#717788";
export const textSecondary = "#bbbbbb";
export const textDisabled = "#dddddd";

export const backgroundPaper = "#ffffff";
export const backgroundDefault = "#f7f7f7";

export const legibilityHigh = "#121c39";
export const legibilityMed = "#717788";
export const legibilityLow = "#a5a9b4";

export const divider = "#cccccc";
export const footer = "#eeeeee";

export const type: any = "light";

const seo: Seo = {
  id: "",
  titleTag: "Wordparrot Portal | Home",
  metaTitle: "Wordparrot Portal",
  metaType: "",
  metaFavicon: "/favicon.ico",
  metaDescription:
    "Register a new site today and start building your branded media library. Fully customizable and hosted on your very own domain.",
  metaImage: "/img/parrot_sm.png",
  metaRobots: "",
  metaViewport: "",
  googleSiteVerification: "",
  googleAnalyticsId: "",
  site: {} as any,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
};

const dns: Dns = {
  id: "",
  isSubdomain: true,
};

const social: Social = {
  id: "",
  facebook: "",
  instagram: "",
  linkedin: "",
  twitter: "",
  github: "",
  site: {} as any,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
};

const theme: Theme = {
  id: "",
  defaultView: "masonry",
  bannerGradient: false,
  site: {} as any,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),

  primaryMain,
  primaryLight,
  primaryDark,
  primaryContrastText,

  secondaryMain,
  secondaryLight,
  secondaryDark,
  secondaryContrastText,
  type,

  successMain: success,
  warningMain: warning,
  errorMain: error,
  infoMain: info,

  textPrimary,
  textSecondary,
  textDisabled,

  backgroundDefault,
  backgroundPaper,

  divider,
  footer,
};

export const DefaultSite = {
  id: "1",
  domain: hubHost,
  defaultSubdomain: hubHost,
  domainApproved: true,
  authScheme: "public",
  title: "Wordparrot Sites",
  organization: "Wordparrot",
  logo: "/img/parrot_sm.png",
  logoApproved: true,
  favicon: "",
  dns,
  images: [],
  seo,
  social,
  theme,
  storage: {} as any,
} as Site;
