import { domainService } from "services/Domain";
import { hubHost } from "theme/default";

export const AppConfig = {
  clientId: "com.wordparrot.automation",
  baseUrl: "https://www.wordparrot.com",
  baseDomain: "wordparrot.com",
  testDomain: "localhost:8080",
  accessUrl: `localhost:8080${domainService.apiRoot}/access`,
  refreshUrl: `localhost:1815${domainService.apiRoot}/refresh`,
  paymentSite: "http://localhost:3000",
  csrfHeader: "X-XSRF-TOKEN",
  refreshHeader: "X-REFRESH-TOKEN",
  siteAccessCookieName: "wordparrot-site-jwt",
  siteRefreshCookieName: "wordparrot-site-jwt-refresh",
  siteCsrfCookieName: "wordparrot-site-csrf",
  siteSelectLocalStorage: "wordparrot-site-select",
  siteSelectedLocalStorage: "wordparrot-site-selected",
  hubAccessHeader: "X-HUB-ACCESS",
  hubAccessToken: "wordparrot-hub-access-token",
  hubHost: hubHost,
  siteIdCookieName: "site-id",
  siteLogoCookieName: "site-logo",
  authSchemeCookieName: "auth-scheme",
  refreshTokenExpiration: "30d",
  accessTokenExpiration: "1h",

  // Android/iOS
  webviewIdentifier: "X-WORDPARROT-WEBVIEW",
  webviewIdentifierDefault: "default",

  localStorageLoginInfo: "wordparrot-login-email",

  adminHost: "admin.wordparrot.com",

  docsHost: "https://docs.wordparrot.com",
  mainHost: "https://www.wordparrot.com",

  signupHost: hubHost,
  signupHostWithProtocol: `https://${hubHost}`,
  signupHostWithCapacitorProtocol: `capacitor://${hubHost}`,

  commentoHost: "https://commento.wordparrot.com",

  localeCookieName: "wordparrot-locale",
  currencyCookieName: "wordparrot-currency",
  countryCookieName: "wordparrot-country",
  defaultImage: "/img/default_profile.png",
  uploaderConfig: {
    url: `${domainService.apiRoot}/orders/`,
    csvUploaderOptions: {
      concurrency: 1,
      allowedContentTypes: [
        "application/pdf",
        "text/plain",
        "text/csv",
        "application/vnd.ms-excel",
      ],
    },
    uploaderOptions: {
      concurrency: 1,
      allowedContentTypes: [
        "text/csv",
        "plain",
        "text/plain",
        "application/pdf",
        "application/msword",
        "application/vnd.ms-word",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ],
    },
  },
  flags: {
    en: "us",
    "en-US": "us",
    "es-US": "us",
    usd: "us",
    es: "es",
    "en-CA": "ca",
    cad: "ca",
    "fr-CA": "ca",
    "en-GB": "gb",
    gbp: "gb",
    "zh-TW": "tw",
    twd: "tw",
    "zh-HK": "hk",
    hkd: "hk",
    "zh-CN": "cn",
    cny: "cn",
    zh: "cn",
    ko: "kr",
    ja: "jp",
    jpy: "jp",
    ms: "my",
    da: "dk",
    hi: "in",
    tl: "ph",
    fr: "fr",
    vi: "vn",
  },
};

export const ApiKeys = {};
